<template>
  <div class="main row m-0">
    <div class="col-md-6 left-column justify-content-center center" :class="{'index-page': !routing}">
      <header class="d-flex justify-content-center">
        <img v-if="whitelabelReady" class="logo" alt="Omni Business" :src="whitelabelLogo" />
      </header>
      <div class="top-options">
        <span class="return-button" v-if="routing">
          <h6 style="color: white;font-size: 12px;font-weight: 500;cursor: pointer;" @click="back()">{{ $t('generic-str.return') }}</h6>
        </span>
        <span class="return-button" v-else>
          <a style="color: white;" href="https://yup.chat">{{ $t('app.back-to-website') }}</a>
        </span>
      <div class="row justify-content-center align-items-center">
        <p style="margin-right: 10px;margin-bottom: 0px;">Selecione o idioma</p>
        <OptLangDropdown />
      </div>
    </div>
      <div class="row mt-3">
        <template v-if="routing">
          <div class="col-md-12">
            <router-view ref="routerView" />
          </div>
        </template>
        <div class="col-12 sub-intro" v-else :class="{'no-margin-top': !routing}">
          <div class="row mt-3">
            <div class="col-12 col-md-12 mb-4">
              <ButtonComponent @click="routing = true" is-block>{{ $t('app.login') }}</ButtonComponent>
            </div>
            <div class="col-12 col-md-12">
              <ButtonComponent variant="secondary" @click="launchRegister()" is-block>{{ $t('app.register') }} </ButtonComponent>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 right-column d-none d-md-block">
      <h3 style="color: white;text-align: center;margin-top: 2em;font-size: 33px;">Bem vindo ao Omni Business!</h3>
      <aside style="height: 50%;" class="samples d-flex align-items-center justify-content-center">
        <div class="samples-bg">
          <!-- Start SVG -->
          <svg width="498" height="306" viewBox="0 0 498 306" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M448.587 270.801H451.587V267.801V66.8008C451.587 56.3074 443.081 47.8008 432.587 47.8008H80.5874C70.094 47.8008 61.5874 56.3074 61.5874 66.8008V267.801V270.801H64.5874H448.587Z" fill="url(#paint0_linear_1373_282)"/>
          <path d="M448.587 270.801H451.587V267.801V66.8008C451.587 56.3074 443.081 47.8008 432.587 47.8008H80.5874C70.094 47.8008 61.5874 56.3074 61.5874 66.8008V267.801V270.801H64.5874H448.587Z" stroke="url(#paint1_linear_1373_282)" stroke-width="6"/>
          <path d="M64.5874 66.8008C64.5874 57.9642 71.7508 50.8008 80.5874 50.8008H432.587C441.424 50.8008 448.587 57.9642 448.587 66.8008V74.8008H64.5874V66.8008Z" fill="url(#paint2_linear_1373_282)"/>
          <rect x="72.5874" y="58.8008" width="8" height="8" rx="4" fill="url(#paint3_linear_1373_282)"/>
          <rect x="84.5874" y="58.8008" width="8" height="8" rx="4" fill="url(#paint4_linear_1373_282)"/>
          <rect x="96.5874" y="58.8008" width="8" height="8" rx="4" fill="url(#paint5_linear_1373_282)"/>
          <g filter="url(#filter0_d_1373_282)">
          <rect x="117.587" y="22.8008" width="278" height="190" rx="16" fill="url(#paint6_linear_1373_282)" shape-rendering="crispEdges"/>
          <rect x="114.587" y="19.8008" width="284" height="196" rx="19" stroke="url(#paint7_linear_1373_282)" stroke-width="6" shape-rendering="crispEdges"/>
          <rect x="227.587" y="37.8008" width="58" height="58" rx="29" fill="url(#paint8_linear_1373_282)"/>
          <rect x="227.587" y="37.8008" width="58" height="58" rx="29" stroke="url(#paint9_linear_1373_282)" stroke-width="2"/>
          <rect x="205.587" y="104.801" width="102" height="18" rx="9" fill="url(#paint10_linear_1373_282)"/>
          <rect x="205.587" y="104.801" width="102" height="18" rx="9" stroke="url(#paint11_linear_1373_282)" stroke-width="2"/>
          <rect x="210.587" y="109.801" width="92" height="8" rx="4" fill="url(#paint12_linear_1373_282)"/>
          <rect x="205.587" y="131.801" width="102" height="18" rx="9" fill="url(#paint13_linear_1373_282)"/>
          <rect x="205.587" y="131.801" width="102" height="18" rx="9" stroke="url(#paint14_linear_1373_282)" stroke-width="2"/>
          <rect x="210.587" y="136.801" width="8" height="8" rx="4" fill="url(#paint15_linear_1373_282)"/>
          <rect x="224.587" y="136.801" width="8" height="8" rx="4" fill="url(#paint16_linear_1373_282)"/>
          <rect x="238.587" y="136.801" width="8" height="8" rx="4" fill="url(#paint17_linear_1373_282)"/>
          <rect x="252.587" y="136.801" width="8" height="8" rx="4" fill="url(#paint18_linear_1373_282)"/>
          <rect x="266.587" y="136.801" width="8" height="8" rx="4" fill="url(#paint19_linear_1373_282)"/>
          <rect x="280.587" y="136.801" width="8" height="8" rx="4" fill="url(#paint20_linear_1373_282)"/>
          <rect x="294.587" y="136.801" width="8" height="8" rx="4" fill="url(#paint21_linear_1373_282)"/>
          <rect x="133.587" y="180.801" width="246" height="16" rx="8" fill="url(#paint22_linear_1373_282)"/>
          <rect x="377.587" y="99.8008" width="36" height="36" rx="6" fill="white"/>
          <rect x="377.587" y="99.8008" width="36" height="36" rx="6" fill="url(#paint23_linear_1373_282)"/>
          <rect x="377.587" y="99.8008" width="36" height="36" rx="6" stroke="url(#paint24_linear_1373_282)" stroke-width="4"/>
          <path d="M388.587 119.067L393.101 123.801L403.587 112.801" stroke="url(#paint25_linear_1373_282)" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
          </g>
          <path d="M44.5874 266.897C44.5874 261.873 48.6597 257.801 53.6831 257.801H190.865C196.482 257.801 201.989 259.358 206.774 262.301C211.56 265.243 217.067 266.801 222.684 266.801H256.587H290.491C296.108 266.801 301.615 265.243 306.4 262.301C311.185 259.358 316.693 257.801 322.31 257.801H459.492C464.515 257.801 468.587 261.873 468.587 266.897V270.641C468.587 273.596 466.191 275.992 463.236 275.992H463.027C461.75 275.992 460.524 276.497 459.616 277.397C458.709 278.296 457.483 278.801 456.205 278.801H444.613C443.335 278.801 442.109 278.296 441.202 277.397C440.295 276.497 439.069 275.992 437.791 275.992H256.587H75.3838C74.1061 275.992 72.8801 276.497 71.9727 277.397C71.0653 278.296 69.8393 278.801 68.5616 278.801H56.9695C55.6918 278.801 54.4658 278.296 53.5584 277.397C52.6511 276.497 51.4251 275.992 50.1474 275.992H49.9389C46.9833 275.992 44.5874 273.596 44.5874 270.641V266.897Z" fill="url(#paint26_linear_1373_282)"/>
          <path d="M19.4127 182.76C10.6682 185.102 4.5874 193.026 4.5874 202.079V214.454C4.5874 238.094 14.5492 260.64 32.0277 276.558L56.5074 298.852C61.0868 303.023 68.088 303.023 72.6674 298.852L97.1471 276.558C114.626 260.64 124.587 238.094 124.587 214.454V202.079C124.587 193.026 118.507 185.102 109.762 182.76L69.7621 172.046C66.3721 171.138 62.8027 171.138 59.4127 172.046L19.4127 182.76Z" fill="url(#paint27_linear_1373_282)" stroke="url(#paint28_linear_1373_282)" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M49.5029 205.075C53.5061 201.058 58.9356 198.801 64.5969 198.801C70.2583 198.801 75.6878 201.058 79.6909 205.075C83.5579 208.955 85.788 214.172 85.9353 219.643C85.9386 219.764 85.937 223.665 85.9354 226.59H81.8847C81.886 223.664 81.887 219.764 81.883 219.643C81.737 215.25 79.9341 211.067 76.8268 207.949C73.5832 204.694 69.184 202.865 64.5969 202.866C60.0098 202.866 55.6106 204.694 52.3671 207.949C49.2597 211.067 47.4569 215.25 47.3109 219.643C47.3069 219.764 47.3003 223.664 47.296 226.59H43.25L43.2586 219.643C43.4058 214.172 45.6359 208.955 49.5029 205.075Z" fill="url(#paint29_linear_1373_282)"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M34.5874 232.38C34.5874 229.822 36.6538 227.748 39.2028 227.748H43.2486H47.2944H81.8841H85.9348H89.972C92.521 227.748 94.5874 229.822 94.5874 232.38V260.169C94.5874 262.727 92.521 264.801 89.972 264.801H39.2028C36.6538 264.801 34.5874 262.727 34.5874 260.169V232.38ZM67.4586 247.953C68.8576 247.011 69.7797 245.4 69.7797 243.571C69.7797 240.668 67.455 238.314 64.5874 238.314C61.7198 238.314 59.3951 240.668 59.3951 243.571C59.3951 245.4 60.3172 247.011 61.7162 247.953C61.8864 248.067 61.8617 249.468 61.8364 250.903C61.8093 252.438 61.7816 254.011 61.9912 254.086C62.5326 254.28 63.9805 254.524 64.5874 254.524C65.1943 254.524 66.6422 254.28 67.1836 254.086C67.3932 254.011 67.3655 252.438 67.3384 250.903C67.3131 249.468 67.2884 248.067 67.4586 247.953Z" fill="url(#paint30_linear_1373_282)"/>
          <path d="M493.087 95.8008L444.587 96.8008L457.587 104.301L493.087 95.8008Z" fill="#9B5CFF"/>
          <path d="M457.587 123.301L493.087 95.8008L457.587 104.301V123.301Z" fill="#7B3EDD"/>
          <path d="M8.5874 61.8008L57.0874 62.8008L44.0874 70.3008L8.5874 61.8008Z" fill="#9B5CFF"/>
          <path d="M44.0874 89.3008L8.5874 61.8008L44.0874 70.3008V89.3008Z" fill="#7B3EDD"/>
          <path d="M442.903 258.521L457.508 167.547C468.019 178.211 482.69 205.645 467.274 237.447C463.284 245.676 455.932 251.612 447.853 255.896L442.903 258.521Z" fill="url(#paint31_linear_1373_282)"/>
          <path d="M442.903 258.521L457.508 167.547C444.188 174.385 421.666 195.847 426.354 230.877C427.566 239.941 432.691 247.881 439.023 254.479L442.903 258.521Z" fill="url(#paint32_linear_1373_282)"/>
          <path d="M456.811 173.655C455.458 182.442 453.136 197.092 450.658 211.879M443.999 248.863C444.341 247.19 444.7 245.375 445.071 243.444M449.18 220.601C452.412 216.218 459.111 206.373 460.052 202.057M449.18 220.601C449.675 217.717 450.169 214.796 450.658 211.879M449.18 220.601C448.095 226.927 447.01 233.068 445.993 238.558M450.658 211.879C449.34 207.635 446.962 197.961 447.997 193.212M445.071 243.444C447.987 239.181 454.507 228.996 457.253 222.36M445.071 243.444C445.371 241.887 445.678 240.253 445.993 238.558M445.993 238.558C443.873 232.256 439.792 218.329 440.424 213.04" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M452.132 262.051L439.001 198.498C448.07 202.654 463.342 215.758 463.106 238.149C463.011 247.185 458.093 255.259 452.132 262.051Z" fill="url(#paint33_linear_1373_282)"/>
          <path d="M452.132 262.051L439.001 198.498C432.322 205.907 423.494 223.991 432.585 244.455C436.253 252.714 443.968 258.177 452.132 262.051Z" fill="url(#paint34_linear_1373_282)"/>
          <path d="M440.069 202.693C441.376 208.817 443.51 219.044 445.576 229.4M450.439 255.417C450.246 254.23 450.029 252.945 449.79 251.581M446.784 235.512C447.816 231.819 449.765 223.661 449.305 220.584M446.784 235.512C446.388 233.49 445.984 231.443 445.576 229.4M446.784 235.512C447.651 239.949 448.473 244.264 449.175 248.133M445.576 229.4C443.647 226.936 439.662 221.16 439.156 217.775M449.79 251.581C450.645 248.045 452.39 239.708 452.539 234.652M449.79 251.581C449.598 250.481 449.392 249.328 449.175 248.133M449.175 248.133C446.204 244.514 440.034 236.364 439.128 232.724" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M466.19 255.646L478.894 208.234C483.612 214.122 489.484 228.165 481.722 243.218C478.59 249.292 472.524 253.079 466.19 255.646Z" fill="url(#paint35_linear_1373_282)"/>
          <path d="M466.19 255.646L478.894 208.234C471.864 210.974 459.757 220.199 458.953 237.117C458.628 243.943 461.987 250.256 466.19 255.646Z" fill="url(#paint36_linear_1373_282)"/>
          <path d="M478.191 211.433C476.994 216.016 474.964 223.652 472.844 231.353M467.298 250.588C467.57 249.72 467.859 248.778 468.161 247.775M471.585 235.894C473.537 233.748 477.624 228.897 478.358 226.66M471.585 235.894C472.005 234.393 472.426 232.872 472.844 231.353M471.585 235.894C470.665 239.187 469.755 242.382 468.916 245.236M472.844 231.353C472.377 229.032 471.645 223.776 472.453 221.316M468.161 247.775C469.939 245.675 473.95 240.633 475.767 237.267M468.161 247.775C468.405 246.966 468.657 246.117 468.916 245.236M468.916 245.236C468.137 241.781 466.734 234.178 467.358 231.41" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M430.015 257.054L423.632 228.991C427.69 230.753 434.577 236.429 434.67 246.359C434.707 250.366 432.598 253.99 430.015 257.054Z" fill="url(#paint37_linear_1373_282)"/>
          <path d="M430.015 257.054L423.632 228.991C420.736 232.335 416.981 240.431 421.192 249.424C422.892 253.054 426.361 255.408 430.015 257.054Z" fill="url(#paint38_linear_1373_282)"/>
          <path d="M424.143 230.841C424.776 233.545 425.812 238.061 426.82 242.634M429.206 254.127C429.11 253.603 429.002 253.035 428.884 252.432M427.409 245.334C427.834 243.687 428.627 240.053 428.395 238.692M427.409 245.334C427.216 244.441 427.018 243.536 426.82 242.634M427.409 245.334C427.833 247.293 428.235 249.199 428.581 250.909M426.82 242.634C425.943 241.559 424.125 239.033 423.871 237.536M428.884 252.432C429.232 250.857 429.932 247.145 429.954 244.902M428.884 252.432C428.789 251.946 428.688 251.437 428.581 250.909M428.581 250.909C427.231 249.33 424.424 245.771 423.99 244.165" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M408.587 245.94C406.378 245.94 404.587 247.731 404.587 249.94V285.199C404.587 295.14 412.646 303.199 422.587 303.199H477.413C487.354 303.199 495.413 295.14 495.413 285.199V249.94C495.413 247.731 493.622 245.94 491.413 245.94H408.587Z" fill="url(#paint39_linear_1373_282)" stroke="url(#paint40_linear_1373_282)" stroke-width="4"/>
          <defs>
          <filter id="filter0_d_1373_282" x="95.5874" y="0.800781" width="336" height="234" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset/>
          <feGaussianBlur stdDeviation="8"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1373_282"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1373_282" result="shape"/>
          </filter>
          <linearGradient id="paint0_linear_1373_282" x1="256.587" y1="50.8008" x2="256.587" y2="267.801" gradientUnits="userSpaceOnUse">
          <stop stop-color="#6024BE"/>
          <stop offset="1" stop-color="#290069"/>
          </linearGradient>
          <linearGradient id="paint1_linear_1373_282" x1="256.587" y1="50.8008" x2="256.587" y2="267.801" gradientUnits="userSpaceOnUse">
          <stop stop-color="#752DE6"/>
          <stop offset="1" stop-color="#5500CC"/>
          </linearGradient>
          <linearGradient id="paint2_linear_1373_282" x1="256.587" y1="50.8008" x2="256.587" y2="74.8008" gradientUnits="userSpaceOnUse">
          <stop stop-color="#6024BE"/>
          <stop offset="1" stop-color="#290069"/>
          </linearGradient>
          <linearGradient id="paint3_linear_1373_282" x1="76.5874" y1="58.8008" x2="76.5874" y2="66.8008" gradientUnits="userSpaceOnUse">
          <stop stop-color="#C199FF"/>
          <stop offset="1" stop-color="#5500CC"/>
          </linearGradient>
          <linearGradient id="paint4_linear_1373_282" x1="88.5874" y1="58.8008" x2="88.5874" y2="66.8008" gradientUnits="userSpaceOnUse">
          <stop stop-color="#C199FF"/>
          <stop offset="1" stop-color="#5500CC"/>
          </linearGradient>
          <linearGradient id="paint5_linear_1373_282" x1="100.587" y1="58.8008" x2="100.587" y2="66.8008" gradientUnits="userSpaceOnUse">
          <stop stop-color="#C199FF"/>
          <stop offset="1" stop-color="#5500CC"/>
          </linearGradient>
          <linearGradient id="paint6_linear_1373_282" x1="256.587" y1="22.8008" x2="256.587" y2="212.801" gradientUnits="userSpaceOnUse">
          <stop stop-color="#6024BE"/>
          <stop offset="1" stop-color="#290069"/>
          </linearGradient>
          <linearGradient id="paint7_linear_1373_282" x1="256.587" y1="22.8008" x2="256.587" y2="212.801" gradientUnits="userSpaceOnUse">
          <stop stop-color="#752DE6"/>
          <stop offset="1" stop-color="#5500CC"/>
          </linearGradient>
          <linearGradient id="paint8_linear_1373_282" x1="256.587" y1="38.8008" x2="256.587" y2="94.8008" gradientUnits="userSpaceOnUse">
          <stop stop-color="#9B5CFF"/>
          <stop offset="1" stop-color="#290069"/>
          </linearGradient>
          <linearGradient id="paint9_linear_1373_282" x1="256.587" y1="38.8008" x2="256.587" y2="94.8008" gradientUnits="userSpaceOnUse">
          <stop stop-color="#752DE6"/>
          <stop offset="1" stop-color="#5500CC"/>
          </linearGradient>
          <linearGradient id="paint10_linear_1373_282" x1="256.587" y1="105.801" x2="256.587" y2="121.801" gradientUnits="userSpaceOnUse">
          <stop stop-color="#6024BE"/>
          <stop offset="1" stop-color="#290069"/>
          </linearGradient>
          <linearGradient id="paint11_linear_1373_282" x1="256.587" y1="105.801" x2="256.587" y2="121.801" gradientUnits="userSpaceOnUse">
          <stop stop-color="#752DE6"/>
          <stop offset="1" stop-color="#5500CC"/>
          </linearGradient>
          <linearGradient id="paint12_linear_1373_282" x1="256.587" y1="109.801" x2="256.587" y2="117.801" gradientUnits="userSpaceOnUse">
          <stop stop-color="#C199FF"/>
          <stop offset="1" stop-color="#5500CC"/>
          </linearGradient>
          <linearGradient id="paint13_linear_1373_282" x1="256.587" y1="132.801" x2="256.587" y2="148.801" gradientUnits="userSpaceOnUse">
          <stop stop-color="#6024BE"/>
          <stop offset="1" stop-color="#290069"/>
          </linearGradient>
          <linearGradient id="paint14_linear_1373_282" x1="256.587" y1="132.801" x2="256.587" y2="148.801" gradientUnits="userSpaceOnUse">
          <stop stop-color="#752DE6"/>
          <stop offset="1" stop-color="#5500CC"/>
          </linearGradient>
          <linearGradient id="paint15_linear_1373_282" x1="214.587" y1="136.801" x2="214.587" y2="144.801" gradientUnits="userSpaceOnUse">
          <stop stop-color="#C199FF"/>
          <stop offset="1" stop-color="#5500CC"/>
          </linearGradient>
          <linearGradient id="paint16_linear_1373_282" x1="228.587" y1="136.801" x2="228.587" y2="144.801" gradientUnits="userSpaceOnUse">
          <stop stop-color="#C199FF"/>
          <stop offset="1" stop-color="#5500CC"/>
          </linearGradient>
          <linearGradient id="paint17_linear_1373_282" x1="242.587" y1="136.801" x2="242.587" y2="144.801" gradientUnits="userSpaceOnUse">
          <stop stop-color="#C199FF"/>
          <stop offset="1" stop-color="#5500CC"/>
          </linearGradient>
          <linearGradient id="paint18_linear_1373_282" x1="256.587" y1="136.801" x2="256.587" y2="144.801" gradientUnits="userSpaceOnUse">
          <stop stop-color="#C199FF"/>
          <stop offset="1" stop-color="#5500CC"/>
          </linearGradient>
          <linearGradient id="paint19_linear_1373_282" x1="270.587" y1="136.801" x2="270.587" y2="144.801" gradientUnits="userSpaceOnUse">
          <stop stop-color="#C199FF"/>
          <stop offset="1" stop-color="#5500CC"/>
          </linearGradient>
          <linearGradient id="paint20_linear_1373_282" x1="284.587" y1="136.801" x2="284.587" y2="144.801" gradientUnits="userSpaceOnUse">
          <stop stop-color="#C199FF"/>
          <stop offset="1" stop-color="#5500CC"/>
          </linearGradient>
          <linearGradient id="paint21_linear_1373_282" x1="298.587" y1="136.801" x2="298.587" y2="144.801" gradientUnits="userSpaceOnUse">
          <stop stop-color="#C199FF"/>
          <stop offset="1" stop-color="#5500CC"/>
          </linearGradient>
          <linearGradient id="paint22_linear_1373_282" x1="256.587" y1="180.801" x2="256.587" y2="196.801" gradientUnits="userSpaceOnUse">
          <stop stop-color="#6024BE"/>
          <stop offset="1" stop-color="#290069"/>
          </linearGradient>
          <linearGradient id="paint23_linear_1373_282" x1="395.587" y1="101.801" x2="395.587" y2="133.801" gradientUnits="userSpaceOnUse">
          <stop stop-color="#6024BE"/>
          <stop offset="1" stop-color="#290069"/>
          </linearGradient>
          <linearGradient id="paint24_linear_1373_282" x1="395.587" y1="101.801" x2="395.587" y2="133.801" gradientUnits="userSpaceOnUse">
          <stop stop-color="#752DE6"/>
          <stop offset="1" stop-color="#5500CC"/>
          </linearGradient>
          <linearGradient id="paint25_linear_1373_282" x1="396.087" y1="112.801" x2="396.087" y2="123.801" gradientUnits="userSpaceOnUse">
          <stop stop-color="#C199FF"/>
          <stop offset="1" stop-color="#5500CC"/>
          </linearGradient>
          <linearGradient id="paint26_linear_1373_282" x1="256.587" y1="257.801" x2="256.587" y2="278.801" gradientUnits="userSpaceOnUse">
          <stop stop-color="#6024BE"/>
          <stop offset="1" stop-color="#290069"/>
          </linearGradient>
          <linearGradient id="paint27_linear_1373_282" x1="64.5874" y1="174.801" x2="64.5874" y2="300.801" gradientUnits="userSpaceOnUse">
          <stop stop-color="#6024BE"/>
          <stop offset="1" stop-color="#290069"/>
          </linearGradient>
          <linearGradient id="paint28_linear_1373_282" x1="64.5874" y1="174.801" x2="64.5874" y2="300.801" gradientUnits="userSpaceOnUse">
          <stop stop-color="#752DE6"/>
          <stop offset="1" stop-color="#5500CC"/>
          </linearGradient>
          <linearGradient id="paint29_linear_1373_282" x1="64.5874" y1="198.801" x2="64.5874" y2="264.801" gradientUnits="userSpaceOnUse">
          <stop stop-color="#C199FF"/>
          <stop offset="1" stop-color="#100028"/>
          </linearGradient>
          <linearGradient id="paint30_linear_1373_282" x1="64.5874" y1="198.801" x2="64.5874" y2="264.801" gradientUnits="userSpaceOnUse">
          <stop stop-color="#C199FF"/>
          <stop offset="1" stop-color="#5500CC"/>
          </linearGradient>
          <linearGradient id="paint31_linear_1373_282" x1="457.508" y1="167.547" x2="442.903" y2="258.521" gradientUnits="userSpaceOnUse">
          <stop stop-color="#752DE6"/>
          <stop offset="1" stop-color="#411980"/>
          </linearGradient>
          <linearGradient id="paint32_linear_1373_282" x1="457.508" y1="167.547" x2="442.903" y2="258.521" gradientUnits="userSpaceOnUse">
          <stop stop-color="#6E22D8"/>
          <stop offset="1" stop-color="#3A1272"/>
          </linearGradient>
          <linearGradient id="paint33_linear_1373_282" x1="439.001" y1="198.498" x2="452.132" y2="262.051" gradientUnits="userSpaceOnUse">
          <stop stop-color="#752DE6"/>
          <stop offset="1" stop-color="#411980"/>
          </linearGradient>
          <linearGradient id="paint34_linear_1373_282" x1="439.001" y1="198.498" x2="452.132" y2="262.051" gradientUnits="userSpaceOnUse">
          <stop stop-color="#6E22D8"/>
          <stop offset="1" stop-color="#3A1272"/>
          </linearGradient>
          <linearGradient id="paint35_linear_1373_282" x1="478.894" y1="208.234" x2="466.19" y2="255.646" gradientUnits="userSpaceOnUse">
          <stop stop-color="#752DE6"/>
          <stop offset="1" stop-color="#411980"/>
          </linearGradient>
          <linearGradient id="paint36_linear_1373_282" x1="478.894" y1="208.234" x2="466.19" y2="255.646" gradientUnits="userSpaceOnUse">
          <stop stop-color="#6E22D8"/>
          <stop offset="1" stop-color="#3A1272"/>
          </linearGradient>
          <linearGradient id="paint37_linear_1373_282" x1="423.632" y1="228.991" x2="430.015" y2="257.054" gradientUnits="userSpaceOnUse">
          <stop stop-color="#752DE6"/>
          <stop offset="1" stop-color="#411980"/>
          </linearGradient>
          <linearGradient id="paint38_linear_1373_282" x1="423.632" y1="228.991" x2="430.015" y2="257.054" gradientUnits="userSpaceOnUse">
          <stop stop-color="#6E22D8"/>
          <stop offset="1" stop-color="#3A1272"/>
          </linearGradient>
          <linearGradient id="paint39_linear_1373_282" x1="450" y1="247.94" x2="450" y2="301.199" gradientUnits="userSpaceOnUse">
          <stop stop-color="#6024BE"/>
          <stop offset="1" stop-color="#290069"/>
          </linearGradient>
          <linearGradient id="paint40_linear_1373_282" x1="450" y1="247.94" x2="450" y2="301.199" gradientUnits="userSpaceOnUse">
          <stop stop-color="#752DE6"/>
          <stop offset="1" stop-color="#5500CC"/>
          </linearGradient>
          </defs>
          </svg>
          <!-- End SVG -->
        </div>
      </aside>
      <p style="text-align: center;
    color: white;
    font-size: 22px;
    font-weight: bold;">Uma maneira mais inteligente de se comunicar</p>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { load } from 'recaptcha-v3';
import DarkMode from '@/assets/mixins/detectDark';
import OptLangRadio from '@/components/OptLangRadio.vue';
import OptLangDropdown from '@/components/OptLangDropdown.vue';
import ButtonComponent from '@/components/ui/ButtonComponent.vue';
import Logo from '@/assets/logo-v2.svg';

export default {
  name: 'AuthIntro',
  components: {
    // OptLangRadio,
    ButtonComponent,
    OptLangDropdown,
  },
  mixins: [DarkMode],
  watch: {
    $route(to, from) {
      // if (from.path === '/2fa') {
      //   this.routing = true;
      // } else if (to.path === '/login') {
      //   this.routing = false;
      // }

      if (to.path === '/login') {
        this.routing = false;
      }
    },
  },
  data() {
    return {
      routing: this.$router.currentRoute.path !== '/login',
      logo: null,
    };
  },
  computed: {
    whitelabelReady() {
      return !!this.$store?.state?.admin?.whitelabel?.id;
    },
    whitelabelLogo() {
      return this.$store?.state?.admin?.whitelabel?.logo ?? Logo;
    },
    whitelabel() {
      return this.$store?.state?.admin?.whitelabel ?? null;
    },
  },
  mounted() {
    const hostname = window.location.hostname;

    switch (hostname) {
      case 'smsycorporativo.algartelecom.com.br':
        this.companyName = 'Algar Telecom';
        break;
      default:
        console.info('Default favicon');
    }
  },
  methods: {
    async launchRegister() {
      await this.$router.push('/register');
      this.routing = true;
    },
    back() {
      this.$refs.routerView.back();
    },
  },
};
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.index-page {
  margin-top: 18vh;
}

.no-margin-top {
  margin-top: 0vh !important;
}

.return-button {
  background: var(--clr-yup-purple);
  color: white;
  font-size: 12px;
  padding: 5px 11px;
  border-radius: 100px;
  font-weight: 500;
  max-height: 2.2em;
}

.main {
  height: 100vh;
  padding: 0; // Remove the padding from main

  .top-options {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3em;
  }

  .left-column {
    background-color: white;
    padding: 53px 37px;

    @media (min-width: 768px) {
      padding: 85px 185px;
    }

    @media (max-width: 1500px) {
      padding: 50px 50px;
    }
  }

  .right-column-whitelabel {
    background: radial-gradient(63.72% 70.71% at 50% 50%, var(--clr-yup-purple) 0%, var(--clr-yup-purple) 100%);
  }

  .right-column {
    /* background: var(--clr-yup-purple-gradient); */
    background: radial-gradient(63.72% 70.71% at 50% 50%, var(--clr-yup-purple) 0%, var(--clr-yup-purple) 100%);
    padding: 39px 23px;

    @media (min-width: 768px) {
      padding: 115px 180px;
    }

    @media (max-width: 1500px) {
      padding: 50px 50px;
    }
  }

  header {
    margin-bottom: 40px;
  }

  .logo {
    width: 126px;
    height: 30px;
  }

  .sub-intro {
    margin-top: 10vh;

    h1 {
      font-size: 23px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-align: center;

      @media (min-width: 768px) {
        font-size: 33px;
        text-align: left;
      }
    }

    h2 {
      color: #747374;
      font-size: 19px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: center;

      @media (min-width: 768px) {
        font-size: 17px;
        text-align: left;
      }
    }
  }

  ::v-deep .primary, .secondary {
    height: 53px !important;
    font-weight: 600 !important;
  }
}
</style>
